export const formatPrice = (price) => `£${price}`;

export const numberFormatter = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export const formatNumber = numberFormatter.format;

export const toPounds = new Intl.NumberFormat('en-GB', {
  style: 'currency',
  currency: 'GBP',
}).format;
