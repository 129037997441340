import { Link, graphql, useStaticQuery } from 'gatsby';
import GatsbyImage from 'gatsby-image';
/** @jsx jsx */
import { jsx } from 'theme-ui';
import { Box, Button, Grid, Text } from 'theme-ui';

export function StayTheNight() {
  const imagesQuery = useStaticQuery(graphql`
    query StayTheNightQuery {
      room: file(relativePath: { eq: "home-room@2x.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 720) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <Box px="m">
      <Grid
        columns={[null, null, '2fr 1fr']}
        gap="m"
        py={['xl', null, 'xxxxl']}
        variant="boxes.container"
      >
        <Box
          sx={{
            boxShadow: 'l',
            borderRadius: 5,
          }}
        >
          <GatsbyImage
            fluid={imagesQuery.room.childImageSharp.fluid}
            sx={{ borderRadius: 5 }}
            style={{ display: 'block' }}
          />
        </Box>
        <Box m={[null, null, 'auto']}>
          <Text
            variant="subhead"
            sx={{
              color: 'orange.dark',
              textAlign: 'center',
            }}
          >
            Stay the night at
          </Text>
          <Box py="l">
            <img
              src="/logo-v.svg"
              sx={{
                width: [140, null, null, 280],
                display: 'block',
                mx: 'auto',
              }}
            />
          </Box>

          <Box pt="xs">
            <Button
              sx={{ width: ['100% !important'] }}
              as={Link}
              to="/accommodation"
            >
              Book a room
            </Button>
          </Box>
        </Box>
      </Grid>
    </Box>
  );
}
