// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-accommodation-js": () => import("./../../../src/pages/accommodation.js" /* webpackChunkName: "component---src-pages-accommodation-js" */),
  "component---src-pages-bunkhouses-js": () => import("./../../../src/pages/bunkhouses.js" /* webpackChunkName: "component---src-pages-bunkhouses-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-food-and-drink-js": () => import("./../../../src/pages/food-and-drink.js" /* webpackChunkName: "component---src-pages-food-and-drink-js" */),
  "component---src-pages-food-menu-js": () => import("./../../../src/pages/food-menu.js" /* webpackChunkName: "component---src-pages-food-menu-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-motor-homes-camper-vans-js": () => import("./../../../src/pages/motor-homes-camper-vans.js" /* webpackChunkName: "component---src-pages-motor-homes-camper-vans-js" */),
  "component---src-pages-nearby-js": () => import("./../../../src/pages/nearby.js" /* webpackChunkName: "component---src-pages-nearby-js" */),
  "component---src-pages-our-history-js": () => import("./../../../src/pages/our-history.js" /* webpackChunkName: "component---src-pages-our-history-js" */),
  "component---src-pages-staying-safe-js": () => import("./../../../src/pages/staying-safe.js" /* webpackChunkName: "component---src-pages-staying-safe-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-pages-webcam-js": () => import("./../../../src/pages/webcam.js" /* webpackChunkName: "component---src-pages-webcam-js" */),
  "component---src-pages-wine-menu-js": () => import("./../../../src/pages/wine-menu.js" /* webpackChunkName: "component---src-pages-wine-menu-js" */),
  "component---src-templates-room-js": () => import("./../../../src/templates/room.js" /* webpackChunkName: "component---src-templates-room-js" */)
}

