import { gql } from '@apollo/client';
import { ItemisedObjectFragment } from './fragments';

export const GET_ROOM_AVAILABILITY = gql`
  query findContinuouslyAvailableResources(
    $startDate: Date!
    $endDate: Date!
    $organisationId: ID!
  ) {
    resources: findContinuouslyAvailableResources(
      organisationId: $organisationId
      startDate: $startDate
      endDate: $endDate
    ) {
      id
      identifier
      description
      blockCost
      blockSize
      dailyCost
      firstAvailability
      lastAvailability
    }
  }
`;

export const CALCULATE_COST = gql`
  query GetRoomCost($booking: CalcInput!) {
    calculateCost(booking: $booking) {
      cost
    }
    calculateItemisedCost(booking: $booking) {
      totalCost
      totalExtrasCost
      totalResourcesCost
      resources {
        ...ItemisedObjectFragment
      }
      extras {
        ...ItemisedObjectFragment
      }
    }
  }
  ${ItemisedObjectFragment}
`;
