import { ApolloProvider } from '@apollo/client';
import React from 'react';

import { Layout } from 'components';
import { apolloClient } from './apollo-client';
import { AppContextProvider } from './app-context';

export function AppProvider(props) {
  const { children, ...rest } = props;
  return (
    <AppContextProvider>
      <ApolloProvider client={apolloClient}>
        <Layout {...rest}>{children}</Layout>
      </ApolloProvider>
    </AppContextProvider>
  );
}
