import { assoc, map } from 'ramda';
// import { alpha } from '@theme-ui/color'

const baseFonts = {
  sub: {
    fontFamily: 'heading',
    fontSize: 'sub',
  },
  xxs: {
    fontFamily: 'heading',
    fontSize: 'xxs',
  },
  xs: {
    fontFamily: 'heading',
    fontSize: 'xs',
  },
  body: {
    fontFamily: 'heading',
    fontSize: 'body',
  },
  s: {
    fontFamily: 'heading',
    fontSize: 's',
  },
  m: {
    fontFamily: 'heading',
    fontSize: ['s', null, 'm'],
    letterSpacing: '-0.01em',
  },
  l: {
    fontFamily: 'heading',
    fontSize: ['m', null, 'l'],
    lineHeight: 1.15,
    letterSpacing: '-0.02em',
  },
  xl: {
    fontFamily: 'heading',
    fontSize: ['l', null, 'xl'],
    lineHeight: 1,
    letterSpacing: '-0.02em',
  },
  xxl: {
    fontFamily: 'heading',
    fontSize: ['l', 'xl', 'xxl'],
    lineHeight: 1,
    letterSpacing: '-0.02em',
  },
};

const cloneFonts = (fontFamily, fontMap) => {
  return map(assoc('fontFamily', fontFamily), fontMap);
};

// example theme.js
const theme = {
  fonts: {
    body: 'Manrope, sans-serif',
    heading: 'Wremena, sans-serif',
    monospace: 'Menlo, monospace',
  },
  text: {
    stnd: cloneFonts('body', baseFonts),
    mono: cloneFonts('mono', baseFonts),
    heading: baseFonts,
    subhead: {
      variant: 'stnd.sub',
      fontWeight: 'heavy',
      fontSize: 'sub',
      textTransform: 'uppercase',
      letterSpacing: '0.1em',
    },
    quote: {
      fontFamily: 'heading',
      fontSize: ['m', null, 'l'],
      fontWeight: 'semibold',
      lineHeight: 1.15,
      letterSpacing: '-0.01em',
      color: 'orange.dark',
    },
  },
  boxes: {
    container: {
      maxWidth: 'container',
      mx: 'auto',
      width: '100%',
    },
    media: {
      maxWidth: '100%',
      display: 'block',
    },
    pin: {
      position: 'absolute',
    },
    measure: {
      maxWidth: 'measure',
      mx: 'auto',
    },
    figure: {
      maxWidth: 'figure',
      mx: 'auto',
    },
  },
  grids: {
    center: {
      outer: {
        gridTemplateColumns: '1fr',
        gridTemplateRows: '1fr',
      },
      inner: {
        gridColumn: 1,
        gridRow: 1,
      },
    },
  },
  sizes: {
    container: 1312,
    figure: 1090,
    measure: 640,
  },
  fontSizes: {
    sub: 12,
    xxs: 14,
    xs: 16,
    body: 18,
    s: 24,
    m: 32,
    l: 48,
    xl: 64,
    xxl: 90,
  },
  space: {
    xxs: 4,
    xs: 8,
    s: 16,
    m: 24,
    l: 32,
    xl: 48,
    xxl: 64,
    xxxl: 96,
    xxxxl: 128,
    xxxxxl: 164,
  },
  maxWidths: {},
  fontWeights: {
    body: 400,
    heading: 600,
    semibold: 600,
    heavy: 800,
  },
  lineHeights: {
    body: 1.66,
    heading: 1,
  },
  colors: {
    text: '#000',
    background: '#fff',

    orange: {
      dark: '#B74A22',
      light: '#D67C5B',
    },
    white: {
      100: '#fff',
      20: 'rgba(255, 255, 255, 0.2)',
    },
    green: {
      dark: '#2E4534',
      light: '#548461',
    },
    black: {
      100: '#000',
      95: '#0D0D0D',
      90: '#1A1A1A',
      80: '#333333',
      70: '#4D4D4D',
      60: '#666666',
      50: '#7F7F7F',
      40: '#999999',
      30: '#B3B3B3',
      20: '#CCCCCC',
      10: '#E6E6E6',
      5: '#F2F2F2',
    },
  },
  forms: {
    input: {
      borderColor: 'black.10',
      paddingX: 's',
      paddingY: 'xs',
      fontFamily: `-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
      minHeight: 48,
      outline: 'none',
      '&:focus': {
        borderColor: 'black.50',
      },
    },
  },
  buttons: {
    base: {
      cursor: 'pointer',
      bg: 'orange.dark',
      color: 'white.100',
      fontFamily: 'body',
      fontSize: 13,
      fontWeight: 'heavy',
      lineHeight: 1,
      textTransform: 'uppercase',
      letterSpacing: '0.1em',
      px: 'm',
      py: 14,
      width: ['100%', null, 'auto'],
      transition: 'all .2s ease',
      '&:disabled': {
        pointerEvents: 'none',
        opacity: 0.5,
      },
    },
    primary: {
      variant: 'buttons.base',
      borderWidth: 2,
      borderColor: 'orange.light',
      borderStyle: 'solid',
      borderRadius: 999,
      boxShadow: 's',
      '&:hover': {
        boxShadow: 'm',
      },
    },
    secondary: {
      variant: 'buttons.primary',
      bg: 'green.dark',
      borderColor: 'green.light',
    },
    square: {
      variant: 'buttons.base',
      display: 'block',
      borderRadius: 0,
      border: 'none',
      py: ['s', null, 'm'],
    },
  },
  borders: {
    underline: 'solid 1px',
  },
  shadows: {
    xs: '0 2px 6px rgba(0, 0, 0, 0.1)',
    s: '0 5px 10px rgba(0, 0, 0, 0.16)',
    m: '0 5px 15px rgba(0, 0, 0, 0.26)',
    l: '0 5px 20px rgba(0, 0, 0, 0.16)',
  },
  links: {
    inline: {
      color: 'black.100',
      borderBottom: 'underline',
      borderColor: 'black.10',
      textDecoration: 'none',
      pb: 1,
    },
    nav: {
      display: 'block',
      fontSize: 'sub',
      textTransform: 'uppercase',
      letterSpacing: '0.1em',
      color: 'black',
    },
    mobile: {
      fontFamily: 'heading',
      fontSize: 'm',
      display: 'block',
      color: 'black',
      lineHeight: 1.25,
      letterSpacing: '-0.02em',
    },
  },

  styles: {
    hr: {
      borderColor: 'black.10',
      my: 'm',
    },
    root: {
      fontFamily: 'body',
      fontSize: [16, null, 18],
      fontWeight: 'body',
      lineHeight: 'body',
      color: 'black.90',
    },
  },
};

export default theme;
