import { useQuery } from '@apollo/client';
import { path } from 'ramda';
import { useCallback } from 'react';

import { useAppContext } from 'utils';
import {
  maybeFormatDate,
  maybeFormatDateMinusDay,
  maybeToDate,
  mergeDateTime,
} from 'utils/dates';

import { GET_ROOM_AVAILABILITY } from 'queries/rooms';

export function useSetNiceDates({ startDate, endDate }) {
  const { setNiceDates } = useAppContext();

  return useCallback(
    (res) => {
      const firstAvailability = res?.firstAvailability;
      const lastAvailability = res?.lastAvailability;

      setNiceDates({
        niceStartDate: mergeDateTime(
          maybeToDate(startDate || endDate),
          firstAvailability
        ),
        niceEndDate: mergeDateTime(
          maybeToDate(endDate || startDate),
          lastAvailability
        ),
      });
    },
    [setNiceDates, startDate, endDate]
  );
}

export default function useRoomAvailability({ startDate, endDate }) {
  const setDates = useSetNiceDates({ startDate, endDate });

  return useQuery(GET_ROOM_AVAILABILITY, {
    variables: {
      startDate: maybeFormatDate(startDate),
      endDate: maybeFormatDateMinusDay(endDate),
      organisationId: process.env.GATSBY_ORGANISATION_ID,
    },
    skip: !startDate || !endDate,
    onCompleted: (res) => {
      const resource = path(['resources', 0], res);
      if (resource) {
        setDates(resource);
      }
    },
  });
}
