import { Link, graphql, navigate, useStaticQuery } from 'gatsby';
import Image from 'gatsby-image';
import { take } from 'ramda';
/** @jsx jsx */
import { Box, Button, Divider, Flex, Grid, Heading, Text, jsx } from 'theme-ui';

import RoomMeta from './room-meta';

import { rejectJordan, useAppContext } from 'utils';

export const CONTENTFUL_ROOMS = graphql`
  query RoomsImages {
    northRooms: contentfulRoomType(slug: { eq: "north-facing" }) {
      ...RoomTypeFragment
      rooms {
        ...RoomFragment
      }
    }
    southRooms: contentfulRoomType(slug: { eq: "south-facing" }) {
      ...RoomTypeFragment
      rooms {
        ...RoomFragment
      }
    }
    bunkhouses: contentfulRoomType(slug: { eq: "bunkhouses" }) {
      ...RoomTypeFragment
      rooms {
        ...RoomFragment
      }
    }
  }
`;

const RoomHeader = ({ room }) => (
  <Box>
    <Heading variant="heading.l">{room.name}</Heading>
    <Divider my="s" />
  </Box>
);

export const RoomDetails = ({ room }) => {
  return (
    <Grid gap="s">
      <RoomMeta room={room} />

      <Text
        variant="stnd.body"
        sx={{
          display: 'flex',
          alignItems: 'flex-end',
          lineHeight: 1,
          py: 5,
          marginTop: 'auto',
        }}
      >
        £{room.nightlyCost} / Night{' '}
        {room.singleNightlyCost && (
          <Text variant="stnd.sub" sx={{ ml: 'xs', color: 'black.50' }}>
            £{room.singleNightlyCost} / Single
          </Text>
        )}
      </Text>
    </Grid>
  );
};

export function Room(props) {
  const { room } = props;

  const { setCurrentModal } = useAppContext();

  const handleOpenModalAvailability = (identifier) => {
    setCurrentModal('availability');
    navigate(`/accommodation?room=${identifier}`, { replace: true });
  };

  const handleOpenModalRoom = (identifier) => {
    setCurrentModal('room-info');
    navigate(`/accommodation?room=${identifier}`, { replace: true });
  };

  return (
    <Flex
      key={room.name}
      bg="white.100"
      sx={{ boxShadow: 'l', flexDirection: 'column' }}
    >
      <Box p="l" sx={{ flex: 'auto' }}>
        <RoomHeader room={room} />
        <RoomDetails room={room} />
      </Box>
      <Grid columns="1fr 1fr" gap={1}>
        <Button
          variant="square"
          onClick={() => handleOpenModalAvailability(room.identifier)}
        >
          Select dates
        </Button>
        <Button
          variant="square"
          bg="orange.light"
          onClick={() => handleOpenModalRoom(room.identifier)}
        >
          View details
        </Button>
      </Grid>
    </Flex>
  );
}

Rooms.defaultProps = { count: 6, includeTypes: false };

export function Rooms(props) {
  const { includeTypes, count } = props;

  const accommodationQuery = useStaticQuery(CONTENTFUL_ROOMS);

  const { northRooms, southRooms, bunkhouses } = accommodationQuery;

  const joinedRooms = [...northRooms.rooms, ...southRooms.rooms];

  const allRooms = [...joinedRooms, ...bunkhouses.rooms].filter(rejectJordan);

  const gridStyles = {
    columnGap: ['m', null, 'l'],
    rowGap: ['xl', 'xxl', 'xxxxl'],
  };

  if (includeTypes) {
    return (
      <Grid sx={gridStyles}>
        <RoomGroup roomType={northRooms} rooms={allRooms} />
        <RoomGroup roomType={southRooms} rooms={allRooms} />
      </Grid>
    );
  }

  return (
    <RoomGrid>
      {take(count, joinedRooms).map((room) => (
        <Room room={room} key={room.name} rooms={allRooms} />
      ))}
    </RoomGrid>
  );
}

export function RoomGroup(props) {
  const { roomType, rooms, hideTopSection } = props;

  return (
    <Box>
      {!hideTopSection && (
        <Box mb={['xl', null, 'xxl']}>
          <RoomTypeSection
            title={roomType.shortName}
            shortDescription={roomType.shortDescription}
            image={roomType?.coverImage?.sizes}
          />
        </Box>
      )}

      <RoomGrid>
        {roomType.rooms.map((room) => (
          <Room room={room} key={room.name} rooms={rooms} />
        ))}
      </RoomGrid>
    </Box>
  );
}

export function RoomGrid(props) {
  const { children } = props;
  return (
    <Grid
      variant="boxes.container"
      columns={[null, 'repeat(2,1fr)', 'repeat(3,1fr)']}
      mx={[0, null, 'auto']}
      sx={{ gap: ['m', null, 'l'] }}
    >
      {children}
    </Grid>
  );
}

function RoomTypeSection(props) {
  const { image, title, shortDescription } = props;
  return (
    <Grid
      columns={[null, null, 'repeat(3,1fr)']}
      gap={['m', null, 'l']}
      sx={{ alignItems: 'center' }}
    >
      <Box>
        <Heading variant="heading.xl">{title}</Heading>
        <Divider />
        <Box>{shortDescription}</Box>
      </Box>
      <Box
        sx={{
          gridColumn: [null, null, '2/4'],
          borderRadius: 5,
          overflow: 'hidden',
          boxShadow: 'm',
        }}
      >
        {image && <Image fluid={image} />}
      </Box>
    </Grid>
  );
}
