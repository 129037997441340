import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS } from '@contentful/rich-text-types';
/** @jsx jsx */
import { Text, jsx } from 'theme-ui';

const options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <Text as="p">{children}</Text>,
  },
};

export const renderContent = (document) =>
  documentToReactComponents(document, options);
