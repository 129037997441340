import PhoneIcon from 'images/svg/phone-icon.svg';
/** @jsx jsx */
import { jsx } from 'theme-ui';
import { Box, Flex, Link, Text } from 'theme-ui';

CallToBook.defaultProps = {
  children: 'to book a table',
  invert: false,
  variant: 'stnd.xxs',
};

export function CallToBook(props) {
  const { children, invert, variant, className } = props;
  return (
    <Flex sx={{ alignItems: 'center' }} className={className}>
      <Box mr="xs">
        <PhoneIcon style={{ display: 'block' }} />
      </Box>

      <Text
        variant={variant}
        sx={{ color: invert ? 'white.100' : 'green.dark' }}
      >
        Call{' '}
        <Link
          pb={4}
          href="tel:01524241274"
          variant="inline"
          sx={{ color: invert ? 'white.100' : 'black.100' }}
        >
          015242 41274
        </Link>{' '}
        {children}
      </Text>
    </Flex>
  );
}
