import gql from 'graphql-tag';

export const ItemisedObjectFragment = gql`
  fragment ItemisedObjectFragment on ItemisedObject {
    start
    end
    title
    identifier
    blockCost
    totalCost
  }
`;
