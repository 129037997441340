import { graphql, useStaticQuery } from 'gatsby';
import GatsbyImage from 'gatsby-image';
import { take } from 'ramda';
/** @jsx jsx */
import { jsx } from 'theme-ui';
import { Box, Flex, Grid, Heading, Text } from 'theme-ui';
import Pin from '../../static/images/svg/pin.svg';

Nearby.defaultProps = {
  count: 3,
};

export function Nearby(props) {
  const { count, component: Component } = props;

  const imagesQuery = useStaticQuery(graphql`
    query NearbyImages {
      allContentfulAttraction {
        edges {
          node {
            id
            name
            description {
              content: description
            }
            image {
              sizes(maxWidth: 1280) {
                ...GatsbyContentfulSizes
              }
            }
          }
        }
      }
      railwayTrip: file(relativePath: { eq: "railway-trip@2x.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 720) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      railwayMuseum: file(relativePath: { eq: "ribblehead-station.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 720) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      scarCave: file(relativePath: { eq: "scar-cave@2x.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 720) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      nearbyFour: file(relativePath: { eq: "nearby-4@2x.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 720) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      nearbyFive: file(relativePath: { eq: "nearby-5@2x.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 720) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      nearbySix: file(relativePath: { eq: "nearby-6@2x.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 720) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      kayaking: file(relativePath: { eq: "kayaking.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 720) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ash: file(relativePath: { eq: "ash.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 720) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const {
    railwayTrip,
    railwayMuseum,
    scarCave,
    nearbyFour,
    nearbyFive,
    nearbySix,
    kayaking,
    ash,
    allContentfulAttraction,
  } = imagesQuery;

  const attractions = [
    {
      image: railwayTrip,
      name: 'Railway Day Trip',
      description:
        'Why not enjoy a Railway Day Trip passing through the head of Dent Dale, Mallerstang and the Eden Valley to Carlisle?',
    },
    {
      image: railwayMuseum,
      name: 'The Railway Museum at Ribblehead Station',
      description:
        'A piece of transport infrastructure carefully converted into a museum and visitor centre. Manned by volunteers, it is well worth a look.',
      website:
        'https://www.visitsettle.co.uk/ribblehead-station-visitor-centre.html',
    },
    {
      image: scarCave,
      name: 'White Scar Cave',
      description:
        'White Scar Cave on the road to Ingleton is the longest show cave in Britain – famous for its stalactites and massive caverns.',
      website: 'https://whitescarcave.co.uk/',
    },
    {
      image: nearbyFour,
      name: 'St Leonard’s Chapel',
      description:
        'The small but charming St Leonard’s Chapel is just a two miles from The Station Inn and a must for those interested in learning more of the viaduct’s history. It is here that many of the railway workers who died during the construction of the Ribblehead line are buried.',
    },
    {
      image: nearbyFive,
      name: 'Salt Lake Quarry Nature Reserve',
      description:
        'Many Station Inn patrons also enjoy visiting the Nature Reserve which is situated in the quarry just across the road. A stunning place where wildlife, flora and fauna are re-establishing themselves following the closure of the quarry. An information system provides narration about both wildlife and the geological formation of the local area.',
      website:
        'https://www.ywt.org.uk/nature-reserves/salt-lake-quarry-nature-reserve',
    },
    {
      image: nearbySix,
      name: 'Market Towns',
      description:
        'The Station Inn is ideally placed between four fabulous northern market towns. Ingleton seven miles to the west; Kirkby Stephen 26 miles away in the north; Hawes 11 miles to the east; Settle 12 miles due south. All have their own individual charm with plenty to do and a host of local crafts to see and sample.',
    },
    {
      image: kayaking,
      name: 'Outdoor Pursuits',
      description:
        'If you are looking for something more exhilarating, then the Yorkshire Dales has it all! Whether you want to test your skills in one of the local underground caving systems; experience the thrill of Gorge Walking / Ghyll Scrambling or wish to experience the joys of Kayaking, Paddle Boarding or Rock Climbing – the Dales has it all and The Station Inn is the perfect base for your chosen activity. There are many outdoor activity providers in the area. At The Station Inn we have frequently used “Alfresco Adventures” for our own team building and staff events, with great success.',
      website: 'https://www.alfrescoadventures.co.uk/',
    },
    {
      image: ash,
      name: 'Ashes Farm Bed & Breakfast',
      description:
        'If there is no accommodation available at the Station Inn on your chosen dates then do not dispair! Just down the road our good friends Jill & Steve run the fabulous Ashes Farm B&B. Rooms and lodges you are guaranteed a lovely stay - and its only a mile away from us so you can still enjoy the Inn, in the evening, and book a table to eat with us',
      website: 'https://www.ashesfarmholidayaccommodation.co.uk/',
    },
  ];

  const res = take(count, attractions);

  if (Component) {
    return res.map((attraction) => (
      <Component attraction={attraction} key={attraction.name} />
    ));
  }

  return res.map((attraction) => (
    <NearbyCard attraction={attraction} key={attraction.name} />
  ));
}

export function NearbyGrouped() {
  return (
    <Box py={['xl', 'xxl', 'xxxl']} bg="green.dark" px={['m']}>
      <Box variant="boxes.container" mx="auto">
        <Box mx="auto" mb="s">
          <Pin style={{ display: 'block', margin: 'auto' }} />
        </Box>
        <Heading
          variant="heading.l"
          sx={{ color: 'white.100', textAlign: 'center' }}
          mb={['xl', 'xxl']}
        >
          Things to do nearby
        </Heading>
        <Grid
          columns={[null, 'repeat(2, 1fr)', 'repeat(3, 1fr)']}
          gap={['l', 'xl', 'xxl']}
          mb={['xl', null, 'xxl']}
        >
          <Nearby count={3} />
        </Grid>
        <Text
          variant="subhead"
          sx={{ color: 'white.100', textAlign: 'center' }}
        >
          View more places nearby
        </Text>
      </Box>
    </Box>
  );
}

export function NearbyExtended() {
  return (
    <Box py={['xl', 'xxl', 'xxxl']} bg="green.dark" px={['m']}>
      <Box variant="boxes.container" mx="auto">
        <Heading
          variant="heading.l"
          sx={{ color: 'white.100', textAlign: 'center' }}
          mb={['xl', 'xxl']}
        >
          Nearby attractions
        </Heading>
        <Grid
          columns={[null, 'repeat(2, 1fr)', 'repeat(3, 1fr)']}
          gap={['l', 'xl', 'xxl']}
          mb={['xl', null, 'xxl']}
        >
          <Nearby count={9} component={NearbyCardFull} />
        </Grid>
      </Box>
    </Box>
  );
}

export function NearbyCardFull(props) {
  const { attraction } = props;
  return (
    <Flex sx={{ flexDirection: 'column' }} key={attraction.name} gap={0}>
      <Box sx={{ boxShadow: 'm' }} mb="m">
        <GatsbyImage
          fluid={attraction.image.childImageSharp.fluid}
          style={{ display: 'block' }}
        />
      </Box>
      <Text
        sx={{
          fontFamily: 'heading',
          fontWeight: 'bold',
          color: 'white.100',
          lineHeight: 1.2,
          fontSize: ['s', null, 'm'],
        }}
        mb="s"
      >
        {attraction.name}
      </Text>
      <Text as="p" mb="l" variant="stnd.xs" sx={{ color: 'white.100' }}>
        {attraction.description}
      </Text>
      {attraction.website && (
        <Text
          variant="subhead"
          sx={{ color: 'white.100', opacity: 0.8, textDecoration: 'none' }}
          href={attraction.website}
          target="_blank"
          as="a"
        >
          Visit website
        </Text>
      )}
    </Flex>
  );
}

export function NearbyCard(props) {
  const { attraction } = props;
  return (
    <Flex
      sx={{ flexDirection: 'column', boxShadow: 'm' }}
      key={attraction.name}
      gap={0}
    >
      <Box>
        <GatsbyImage
          fluid={attraction.image.childImageSharp.fluid}
          style={{ display: 'block' }}
        />
      </Box>
      <Flex
        bg="green.light"
        p="s"
        sx={{ flex: 'auto', alignItems: 'center', justifyContent: 'center' }}
      >
        <Text
          variant="stnd.s"
          sx={{
            fontWeight: 'semibold',
            color: 'white.100',
            textAlign: 'center',
            fontSize: ['xs', 's'],
            lineHeight: ['xs', 's'],
          }}
        >
          {attraction.name}
        </Text>
      </Flex>
    </Flex>
  );
}
