import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
/** @jsx jsx */
import { Box, Flex, Grid, Heading, Text, jsx } from 'theme-ui';
import slugify from 'underscore.string/slugify';

import { formatNumber } from 'utils';

export function FoodMenuNav(props) {
  const { options } = props;

  return (
    <Box
      sx={{
        pt: ['m', 'l', 'xxl'],
        pb: [null, null, 'xxl'],
        px: ['m', null, 'l'],
        borderRight: 'solid 1px',
        borderColor: 'black.10',
        position: 'sticky',
        top: 0,
        left: 0,
        alignSelf: 'flex-start',
        bg: 'white.100',
        display: ['none', false, 'block'],
      }}
    >
      {options.map((menu) => {
        const title = menu.node.title ?? menu.node.name;
        return (
          <Box key={title}>
            <Box
              sx={{
                mb: 's',
                display: 'block',
                textDecoration: 'none',
                color: 'black.100',
              }}
              as="a"
              href={`#${slugify(title)}`}
            >
              {title}
            </Box>
          </Box>
        );
      })}
    </Box>
  );
}
