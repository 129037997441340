import { useLocation } from '@reach/router';
import { useEffect } from 'react';
/** @jsx jsx */
import { Flex, jsx } from 'theme-ui';

import { DialogContent, DialogOverlay } from '@reach/dialog';
import VisuallyHidden from '@reach/visually-hidden';

import '@reach/dialog/styles.css';

export default function RoomModal({ children, isOpen, onDismiss, className }) {
  const { pathname } = useLocation();

  useEffect(() => {
    onDismiss();
  }, [pathname]);

  return (
    <div>
      <DialogOverlay
        sx={{
          background: 'hsla(0, 100%, 0%, 0.8)',
          paddingLeft: [0, null, '3vw'],
          paddingRight: [0, null, '3vw'],
          zIndex: 9999,
          display: ['flex', null, 'block'],
        }}
        isOpen={isOpen}
        onDismiss={onDismiss}
      >
        <DialogContent
          aria-label="Modal"
          sx={{
            boxShadow: '0px 10px 50px hsla(0, 0%, 0%, 0.33)',
            maxWidth: [960],
            width: '100%',
            margin: [0, null, '10vh auto'],
            outline: 'none',
            padding: ['1rem', '2rem'],
            paddingBottom: ['5vh', '2rem'],
            overflow: ['scroll', null, 'initial'],
            position: 'relative',
          }}
          className={className}
        >
          <Flex
            sx={{
              justifyContent: 'flex-end',
              position: 'absolute',
              top: '2rem',
              right: '2rem',
            }}
          >
            <button
              className="close-button"
              onClick={onDismiss}
              sx={{
                border: 'solid 1px',
                borderColor: 'black.10',
                padding: '0.25rem',
                cursor: 'pointer',
                minWidth: 34,
                textAlign: 'center',
                borderRadius: 5,
              }}
            >
              <VisuallyHidden>Close</VisuallyHidden>
              <span aria-hidden>×</span>
            </button>
          </Flex>

          {children}
        </DialogContent>
      </DialogOverlay>
    </div>
  );
}
