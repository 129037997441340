import { Link } from 'gatsby';
/** @jsx jsx */
import { Box, Flex, Grid, Text, jsx } from 'theme-ui';

function FooterLink(props) {
  const { children, ...rest } = props;
  return (
    <Text
      variant="stnd.sub"
      sx={{
        lineHeight: 2,
        display: 'block',
        textDecoration: 'none',
        color: 'black.100',
      }}
      {...rest}
    >
      {children}
    </Text>
  );
}

export function Footer() {
  return (
    <Box
      sx={{
        borderTop: 'solid 1px',
        borderColor: 'black.10',
        textAlign: ['center', null, 'left'],
      }}
      px={['m', null, 'l']}
    >
      <Box variant="boxes.container">
        <Grid
          columns={[null, null, '3.5fr 1fr 3.5fr']}
          gap={20}
          py={['l', null, 'xxl', 'xxxl']}
        >
          <Grid gap={20}>
            <Box>
              <FooterLink
                as="a"
                href="mailto:bookings@thestationinnribblehead.com"
              >
                bookings@thestationinnribblehead.com
              </FooterLink>
              <FooterLink as="a" href="tel:015242 41274">
                015242 41274
              </FooterLink>
            </Box>
            <Box>
              <FooterLink
                as="a"
                href="https://www.facebook.com/thestationinnribblehead/"
                target="_blank"
              >
                Facebook
              </FooterLink>
              <Link
                to="/terms-and-conditions"
                sx={{ textDecoration: 'none', color: 'inherit' }}
              >
                <FooterLink>Terms & conditions</FooterLink>
              </Link>
            </Box>
            <Box sx={{ display: ['none', null, null, 'block'] }}>
              <FooterLink>
                © {new Date().getFullYear()}, The Station Inn, Ribblehead
              </FooterLink>
            </Box>
          </Grid>

          <Box sx={{ order: [0, null, 'initial'] }}>
            <img
              src="/logo-v.svg"
              sx={{
                maxWidth: [140, null, '100%'],
                display: 'block',
                marginX: 'auto',
              }}
            />
          </Box>
          <Box sx={{ display: [null, null, 'flex'] }}>
            <Box ml={[null, null, 'auto']}>
              <FooterLink>Website by Lawrence Gosset</FooterLink>
              <FooterLink>Photos and video by David Sheldrick</FooterLink>
              <FooterLink>Design by Nigel Payne</FooterLink>
            </Box>
          </Box>
          <Box sx={{ display: ['block', null, null, 'none'] }}>
            <FooterLink>
              © {new Date().getFullYear()}, The Station Inn, Ribblehead
            </FooterLink>
          </Box>
        </Grid>
      </Box>
    </Box>
  );
}
