import { T, always, cond, includes, pathOr, take } from 'ramda';
import React from 'react';
/** @jsx jsx */
import { Box, Button, Divider, Flex, Grid, Heading, Text, jsx } from 'theme-ui';

import BedIcon from 'images/svg/bed-icon.svg';
import PawIcon from 'images/svg/paw-icon.svg';

const getFeatureIcon = cond([
  [
    includes('Dogs Permitted'),
    always(<PawIcon sx={{ display: 'block' }} width={24} height={21} />),
  ],
  [
    includes('Pull Out Bed Available'),
    always(<BedIcon sx={{ display: 'block' }} width={25} height={22} />),
  ],
  [T, always(null)],
]);

export default function RoomMeta({ room }) {
  const roomFeatures = room.features;

  return (
    <Grid gap="xs">
      {room.subheading && <Text variant="subhead">{room.subheading}</Text>}

      {roomFeatures && (
        <Grid>
          {roomFeatures.map((option, index) => (
            <Flex key={index} sx={{ fontSize: 'xxs', alignItems: 'center' }}>
              <Box sx={{ marginRight: 'xs', color: 'inherit' }}>
                {getFeatureIcon(option)}
              </Box>
              {option}
            </Flex>
          ))}
        </Grid>
      )}
    </Grid>
  );
}
