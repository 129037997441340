import React from 'react';
import { ThemeProvider } from 'theme-ui';

import 'components/base.css';

import { useAppContext } from 'utils';
import theme from 'utils/theme';

import { Footer, Header, RoomModal } from 'components';
import RoomAvailabilityModal from './room-availability-modal';
import RoomModalInfo from './room-modal-info';

export function Layout(props) {
  const { children, location } = props;
  const { setCurrentModal, currentModal } = useAppContext();

  return (
    <>
      <link
        href="https://fonts.googleapis.com/css2?family=Manrope:wght@500;600;800&display=swap"
        rel="stylesheet"
      />
      <ThemeProvider theme={theme}>
        <RoomAvailabilityModal
          onDismiss={() => setCurrentModal(null)}
          isOpen={currentModal === 'availability'}
        />
        <RoomModalInfo
          onDismiss={() => setCurrentModal(null)}
          isOpen={currentModal === 'room-info'}
        />
        <Header key={location?.key} />

        {children}

        <Footer />
      </ThemeProvider>
    </>
  );
}
