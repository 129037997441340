import Image from 'gatsby-image';
/** @jsx jsx */
import { Box, Flex, Grid, jsx } from 'theme-ui';

export function TwoColumn(props) {
  const { children, image, bg } = props;
  return (
    <Grid columns={[null, null, '1fr 1fr']} bg={bg} gap={0}>
      <Flex py={['l', null, 'xxl']} my="auto">
        <Box
          ml="auto"
          mr={[null, null, null, 'xxxxl']}
          sx={{ maxWidth: [null, null, null, 540] }}
          px={['m', 'l']}
        >
          {children}
        </Box>
      </Flex>
      <Grid>
        <Image fluid={image} style={{ display: 'block' }} />
      </Grid>
    </Grid>
  );
}
