/** @jsx jsx */
import { Flex, Text, jsx } from 'theme-ui';

import { eventFormat } from 'utils/dates';

export function EventDate(props) {
  const { eventStart, eventEnd } = props;

  if (eventEnd) {
    return (
      <Flex sx={{ flexWrap: 'wrap' }}>
        <Text variant="stnd.sub">From {eventFormat(eventStart)}</Text>
        <Text variant="stnd.sub" sx={{ whiteSpace: 'pre' }}>
          {' '}
          until {eventFormat(eventEnd)}
        </Text>
      </Flex>
    );
  }

  return <Text variant="stnd.body">{eventFormat(eventStart)}</Text>;
}
