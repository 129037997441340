import { useBreakpointIndex, useResponsiveValue } from '@theme-ui/match-media';
import eachDayOfInterval from 'date-fns/fp/eachDayOfInterval';
import { any, includes, map } from 'ramda';
import { useCallback, useMemo, useState } from 'react';
/** @jsx jsx */
import { Box, jsx } from 'theme-ui';
import { dateFormat } from 'utils/dates';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

import { DateRangePicker } from 'react-dates';

import { useAppContext } from 'utils/app-context';

export default function BookingCalendar() {
  const { setDates, startDate, endDate, activeRoom } = useAppContext();
  const [focusedInput, setFocused] = useState(false);

  const handleDateChange = useCallback(setDates, [
    startDate,
    endDate,
    setDates,
  ]);

  const breakPointIndex = useBreakpointIndex();

  const isMobile = breakPointIndex <= 1;

  return (
    <Box>
      <DateRangePicker
        block
        numberOfMonths={1}
        startDateId="startDate"
        endDateId="endDate"
        startDate={startDate}
        endDate={endDate}
        displayFormat="ddd MMM D"
        autoFocus
        // isDayBlocked={(day) => {
        //   const niceDay = dateFormat(day.toDate())
        //   return disabledDays.includes(niceDay)
        // }}
        onDatesChange={handleDateChange}
        focusedInput={focusedInput}
        onFocusChange={(focusedInput) => {
          setFocused(focusedInput);
          document.activeElement.blur();
        }}
        keepFocusOnInput
        withFullScreenPortal={isMobile}
      />
    </Box>
  );
}
