import { Link } from 'gatsby';
/** @jsx jsx */
import { Box, Button, Flex, Text, jsx } from 'theme-ui';

RoomAvailabilityButtons.defaultProps = {
  identifier: 'salt-lake',
};

export default function RoomAvailabilityButtons() {
  return (
    <Box>
      <Flex
        sx={{
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Text variant="stnd.body" sx={{ color: 'black.50' }}>
          Select your preferred dates
        </Text>
      </Flex>
    </Box>
  );
}
